import React from 'react';
import FlexibleContent from '../components/FlexibleContent';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroCaseStudySingle from '../components/Static/HeroCaseStudySingle';

const CaseStudy = ({ location, pageContext }) => {
  const { seo, hero, flexibleContent, hreflang } = pageContext;

  return (
    <Layout>
      <SEO pathname={location.pathname} seo={seo} hreflang={hreflang} />
      <HeroCaseStudySingle
        breadcrumbs={seo.breadcrumbs}
        title={hero.title}
        description={hero.description}
        links={hero.links}
        image={hero.image}
        imageMobile={hero.imageMobile}
      />
      <FlexibleContent prefix='case_study' components={flexibleContent} />
    </Layout>
  );
};

export default CaseStudy;
